#viewer-container {
  position: absolute;
  overflow: hidden;
  width: 100vw;
}

.viewer-root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-rows: 100%;
  grid-gap: 2em;
  align-items: stretch;
}

.viewer-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: 2em auto;
  transform: translateX(0px);
}

.offscreen-image {
  display: none;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: 2em auto;
}

.bumper {
  display: none;
}

.chevron {
  fill: #0b0b0b;
  stroke: #0b0b0b;
  width: 24px;
  height: 24px;
}

nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav a {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #f5f5f5;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-decoration: none;
}

nav:hover {
  filter: brightness(115%);
}

#close-link {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#close-button {
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 1em;
  left: 1em;
  color: white;
  background-color: #f5f5f5;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-decoration: none;
}

#close-button:hover {
  filter: brightness(115%);
}

#close-cross {
  stroke: #0b0b0b;
  fill: #0b0b0b;
}

@media (hover: none) and (pointer: coarse) {
  nav {
    display: none;
  }
  .viewer-root {
    grid-template-columns: 100vw 100vw 100vw;
    width: 300vw;
    transform: translateX(calc(-100vw - 2em));
  }
  .offscreen-image {
    display: block;
  }
  .bumper {
    display: block;
  }
}
