#gallery-grid {
  align-items: center;
  align-content: start;
  margin: 2em auto;
  padding: 0 2em;
  max-width: 1800px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(301px, 1fr));
  grid-template-rows: repeat(auto-fit);
  grid-gap: 2em;
  height: 100%;
}

#title {
  grid-column-start: 1;
  grid-column-end: -1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

#name {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#profile-pic {
  margin: 0 2em;
  border-radius: 50%;
  border: 3px solid white;
  width: 128px;
  height: 128px;
}

h1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  margin: 0;
  margin-bottom: 0.25em;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
}

a {
  color: #0b0b0b;
}

.galleryItem {
  position: relative;
}

.galleryItem:hover img {
  filter: brightness(50%) blur(2px);
}

.galleryImage {
  object-fit: contain;
  width: 100%;
}

.itemLabel {
  display: none;
  position: absolute;
  color: white;
}

.galleryItem:hover .itemLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: x-large;
  text-transform: uppercase;
  cursor: pointer;
}
